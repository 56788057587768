/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Budget from "../components/ListItems/Budget";
import EditBudget from "../components/Modals/EditBudget";
import { BudgetCategoryAmount, BudgetItem, BudgetVariant, CategoryItem, CurrentUser, DateTime, FamilyHasAccess, TimeframeDays, TimeframeItem, TransactionItem, UserItem } from "../types";
import { useItems } from "../features/Fetch/Items";
import { Grid, IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight, FileEarmarkSpreadsheetFill, PlusLg } from "react-bootstrap-icons";
import { ModalProps, setProps } from "../features/Slices/modalSlice";
import { useNavigate } from "react-router-dom";
import BudgetReport from "../components/Modals/BudgetReport";
import Date from "../components/Inputs/Date";
import { useAuth } from "../features/Fetch/Auth";
import { pageLoad } from "../App";
import { Features } from "../constants";
import Unbudget from "../components/ListItems/Unbudget";

const FilteredBudgets = (props: any) => {
	let repo = useItems();
	let auth = useAuth();
	let user: CurrentUser = useSelector((state: any) => state.userData.value);
	useEffect(() => {
		HideModal();
		if (pageLoad.instance === 2 && user.token && user.isActive) {
			auth.setUserContext().then(v => {
				repo.GetBudgetsAsync();
				repo.GetCategoriesAsync();
				repo.GetTimeframesAsync();
				repo.GetTransactionsAsync();
				repo.GetUsersAsync();
			}).catch(e => {
				if (e === "unauthorized") {
					auth.logout();
				}
			});
		}
	}, []);

	let navigate = useNavigate();
	let dispatch = useDispatch();

	let [currentDate, setCurrentDate] = useState(new DateTime());

	let modal: ModalProps = useSelector((state: any) => state.modal.value);

	let transactions: TransactionItem[] = useSelector((state: any) => state.transactions.value);
	let checkRequests: TransactionItem[] = useSelector((state: any) => state.checkRequests.value);
	let timeframes: TimeframeItem[] = useSelector((state: any) => state.timeframes.value);
	let categories: CategoryItem[] = repo.SortedCategories();
	let budgets: BudgetItem[] = useSelector((state: any) => state.budgets.value);
	let users: UserItem[] = useSelector((state: any) => state.users.value);
	let filteredBudgets = budgets.filter(b => !b.isInactive);

	let canLoad = transactions && timeframes && categories && budgets && user;

	let earliest = new DateTime();
	let sumUnbudgeted = 0;

	let title = '';
	switch (location.pathname.toLowerCase()) {
		case "/budgets/income":
			filteredBudgets = filteredBudgets.filter(b => b.isIncome);
			title = 'Income';
			break;
		case "/budgets/limited":
			filteredBudgets = filteredBudgets.filter(b => !b.isIncome && b.variant.amount > 0);
			title = 'Limited';
			earliest = new DateTime([...filteredBudgets.filter(b => !b.isIncome && b.variant.amount > 0)].map(b => b.variant.startDate.Value).reduce((minDate: Date, currentDate: Date) => currentDate < minDate ? currentDate : minDate, new DateTime().Value));
			sumUnbudgeted = ([] as BudgetCategoryAmount[]).concat.apply([], transactions?.filter(t => t.transactionDate.startOfDay().Value >= earliest.Value).map(t => t.budgetCategoryAmounts?.filter(a => a.budgetId < 1))).reduce((total, t) => total + t.amount, 0);
			break;
		case "/budgets/unlimited":
			filteredBudgets = filteredBudgets.filter(b => !b.isIncome && b.variant.amount === 0);
			title = 'Unlmited';
			break;
		case "/budgets/all":
			title = 'All';
			break;
		default:
			navigate('/budgets');
	}

	if (!FamilyHasAccess(user, Features.Budgets)) {
		navigate("/");
		return <></>;
	}
	if (!FamilyHasAccess(user, Features.Income) && title !== 'All') {
		navigate("/budgets/all");
		return <></>;
	}

	return (
		<>
			<Grid container rowSpacing={3} justifyContent="center">
				<Grid item xs={8}>
					<h2 className="mt-2 text-app">{title} Budgets</h2>
				</Grid>
				<Grid item xs={3}>
					{!canLoad && (< p ><em>Loading...</em></p>)}
					{canLoad && user.isHoH && (
						<>
							<IconButton
								className="text-app right"
								aria-label='new expense'
								onClick={OpenNewModal}>
								<PlusLg />
							</IconButton>
						</>
					)}
				</Grid>
				{canLoad && (
					<div className="border border-app mb-3 start-hint budget-container">
						<Grid container spacing={2}>
							{budgets.length > 0 ? (
								<>
									<Grid item xs={3} className="centered align-self-center">
										<ChevronLeft className="h3" onClick={BackDate} />
									</Grid>
									<Grid item xs={6} className="centered">
										<Date Size={12} Label="As of..." Value={currentDate} OnChange={UpdateDate} />
									</Grid>
									<Grid item xs={3} className="centered align-self-center">
										<ChevronRight className="h3" onClick={ForwardDate} />
									</Grid>
								</>
							): (
									<>
										<Grid item xs={3} className="centered align-self-center">
										</Grid>
										<Grid item xs={6} className="centered">
											<Date Size={12} Label="As of..." Value={currentDate} OnChange={UpdateDate} />
										</Grid>
										<Grid item xs={3} className="centered align-self-center">
										</Grid>
										<Grid item xs={3}></Grid>
										<Grid item xs={6} className="centered">
											{user.isHoH ? "Select the Plus(+) button above to add your first budget" : "You currently have no budgets assigned to you"}
										</Grid>
										<Grid item xs={3}></Grid>
									</>
							)}
							<Grid item xs={12} className="mb-3"></Grid>
						</Grid>
						<div className="budget-list">
							{
								filteredBudgets.filter(b => user.isHoH || b.isMine).slice().sort((a, b) => a.description.localeCompare(b.description)).map(budget => {
									return (
										<Budget
											Item={budget}
											Categories={categories}
											OnClick={() => navigate(`/budget/${budget.budgetId}/${currentDate.toQueryString()}`)}
											key={`budget${budget.budgetId}`} />
									);
								})
							}
							{sumUnbudgeted > 0 &&
								<Unbudget
									Since={earliest}
									Total={sumUnbudgeted}
									OnClick={() => navigate(`/unbudget`)}
									key={`budget0`} />
							}
						</div>
                        <h2 className="btn-list btn-total btn-report">
							<Grid container onClick={() => navigate("/reports")}>
								<Grid item xs={2} className="centered">
									<FileEarmarkSpreadsheetFill />
								</Grid>
								<Grid item xs={10}>
									View Reports
								</Grid>
							</Grid>
						</h2>
					</div>
				)}
			</Grid>
		</>
	);

	function HideModal() {
		dispatch(setProps({ ...modal, IsOpen: false }));
	};

	function SaveBudget(item: BudgetItem) {
		if (!user.isHoH) return;
		let variant = { ...item.variant };
		repo.UpdateBudgetAsync(item, variant, item.budgetId === 0, false);
		HideModal();
		repo.GetBudgetsWithDateAsync(currentDate);
	}

	function DeleteBudget(item: BudgetItem) {
		if (!user.isHoH) return;
		repo.UpdateBudgetAsync(item, item.variant, false, true);
		HideModal();
		repo.GetBudgetsWithDateAsync(currentDate);
	}

	function OpenNewModal() {
		let budget: BudgetItem = {
			budgetId: 0,
			description: "",
			color: "#00619b",
			categoryIds: [categories[0].categoryId],
			timeframeId: timeframes[0].timeframeId,
			variant:
			{
				budgetVariantId: 0,
				budgetId: 0,
				amount: 0,
				startDate: new DateTime(),
				endDate: null,
				isDefault: true,
				isInactive: false
			},
			userIds: user.includeAllUsersInBudgets ? users.map(u => u.userAccountId) : [],
			isIncome: false,
			isMine: false,
			isViewTransactions: true,
			isInactive: false,
			requiresApproval: true,
			categories: [],
			amountSpent: 0,
			amountRemaining: 0
		} as BudgetItem;
		modal = {
			...modal,
			Body: <EditBudget Item={budget} Categories={categories} Timeframes={timeframes} OnSave={SaveBudget} Close={HideModal} />,
			IsOpen: true,
			WasOpen: false
		}
		dispatch(setProps(modal));
	}

	function OpenModal(idx: number) {
		let budget = budgets.find(t => t.budgetId === idx);
		if (budget) {
			let modalBudget = {
				budgetId: budget.budgetId,
				description: budget.description,
				color: budget.color,
				categoryIds: budget.categoryIds,
				timeframeId: budget.timeframeId,
				variant:
					{
						budgetVariantId: budget.variant.budgetVariantId,
						budgetId: budget.variant.budgetId,
						amount: budget.variant.amount,
						startDate: budget.variant.startDate,
						isDefault: budget.variant.isDefault,
						endDate: budget.variant.endDate
					} as BudgetVariant,
				userIds: budget.userIds,
				isMine: budget.isMine,
				requiresApproval: budget.requiresApproval,
				categories: budget.categories,
				amountSpent: budget.amountSpent,
				amountRemaining: budget.amountRemaining
			} as BudgetItem;
			modal = {
				...modal,
				Body: <BudgetReport Budget={modalBudget} Categories={categories} Transactions={transactions} CheckRequests={checkRequests} StartDate={modalBudget.variant.startDate} EndDate={new DateTime()} User={user} OpenBudget={() => OpenBudgetModal(idx)} Close={HideModal} ViewReport={ViewReport} />,
				IsOpen: true,
				WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}

	function OpenBudgetModal(idx: number) {
		let budget = budgets.find(t => t.budgetId === idx);
		if (budget) {
			let modalBudget = {
				budgetId: budget.budgetId,
				description: budget.description,
				color: budget.color,
				categoryIds: budget.categoryIds,
				timeframeId: budget.timeframeId,
				variant:
					{
						budgetVariantId: budget.variant.budgetVariantId,
						budgetId: budget.variant.budgetId,
						amount: budget.variant.amount,
						startDate: budget.variant.startDate,
						isDefault: budget.variant.isDefault,
						endDate: budget.variant.endDate
					} as BudgetVariant,
				userIds: budget.userIds,
				isMine: budget.isMine,
				requiresApproval: budget.requiresApproval,
				categories: budget.categories,
				amountSpent: budget.amountSpent,
				amountRemaining: budget.amountRemaining
			} as BudgetItem;
			modal = {
				...modal,
				Body: <EditBudget Item={modalBudget} Categories={categories} Timeframes={timeframes} OnSave={SaveBudget} OnDelete={DeleteBudget} Close={HideModal} />,
				IsOpen: true,
				WasOpen: false
			}
			dispatch(setProps(modal));
		}
	}

	function ViewReport() {
		HideModal();
		navigate("/report/categories");
	}

	function BackDate() {
		if (budgets.length > 0) {
			let filtered = filteredBudgets.filter(b => b.variant?.timeframe !== null && b.variant?.timeframe !== undefined);
			if (!filtered || filtered.length === 0) filtered = budgets.filter(b => b.variant?.timeframe !== null && b.variant?.timeframe !== undefined);
			if (!filtered || filtered.length === 0) filtered = [{
				variant: {
					timeframe: {
						days: 7,
						months: 0
					} as TimeframeItem
				} as BudgetVariant
			} as BudgetItem];

			let sorted = budgets.slice().sort((a, b) => TimeframeDays(b.variant.timeframe) - TimeframeDays(a.variant.timeframe));
			let days = TimeframeDays(sorted[0].variant.timeframe);
			let start = currentDate.addDays(-1 * days);
			repo.GetBudgetsWithDateAsync(start.addDays(-1));
			setCurrentDate(start.addDays(-1));
		}
	}

	function ForwardDate() {
		if (budgets.length > 0) {
			let filtered = filteredBudgets.filter(b => b.variant?.timeframe !== null && b.variant?.timeframe !== undefined);
			if (!filtered || filtered.length === 0) filtered = budgets.filter(b => b.variant?.timeframe !== null && b.variant?.timeframe !== undefined);
			if (!filtered || filtered.length === 0) filtered = [{
				variant: {
					timeframe: {
						days: 7,
						months: 0
					} as TimeframeItem
				} as BudgetVariant
			} as BudgetItem];

			let sorted = budgets.slice().sort((a, b) => TimeframeDays(b.variant.timeframe) - TimeframeDays(a.variant.timeframe));
			let days = TimeframeDays(sorted[0].variant.timeframe);
			let end = currentDate.addDays(days);
			repo.GetBudgetsWithDateAsync(end!);
			setCurrentDate(end!);
		}
	}

	function UpdateDate(newValue: any) {
		newValue = new DateTime(newValue);
		repo.GetBudgetsWithDateAsync(newValue);
		setCurrentDate(newValue);
	}
}

export default FilteredBudgets;